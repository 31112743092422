import React, { useContext } from "react"
import Query from "../components/Query"
import { AppContext } from "../contexts/AppContext"
import NotAuthorized from "../components/NotAuthorized"
import ContentUserArtist from "../components/ContentUserArtist"
import { QUERY_ARTIST_BY_ID_BASIC_DETAILS } from "../queries/page/artist"

const UserStation = () => {
  const { userState, countries } = useContext(AppContext)
  return (
    <React.Fragment>
      {userState.artist && userState.artist.id ? (
        <Query
          query={QUERY_ARTIST_BY_ID_BASIC_DETAILS}
          variables={{
            id: userState.artist.id,
          }}
          fetchPolicy="network-only"
        >
          {({ data }) => {
            return (
              <React.Fragment>
                <ContentUserArtist data={data} countries={countries} />
              </React.Fragment>
            )
          }}
        </Query>
      ) : (
        <NotAuthorized>
          You are not authorized to see contents of this page
        </NotAuthorized>
      )}
    </React.Fragment>
  )
}

export default UserStation
