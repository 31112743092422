import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import * as St from "../Styled"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../contexts/AppContext"
import { useMutation } from "@apollo/client"
import { MUTATION_UPDATE_ARTIST } from "../../queries/page/artist"

import axios from "axios"
import auth from "../../auth/auth"

import { awsArtistImagesPath, COUNTRY_ALL } from "../../constants/props"
import useButtonLoader from "../../hooks/useButtonLoader"
import ProgressContent from "../ProgressBar"

const ProfileUpdateForm = props => {
  let { userState } = useContext(AppContext)

  const artistData =
    props.data && props.data.artist ? props.data.artist : undefined

  const { handleSubmit, register, errors } = useForm({
    defaultValues: artistData,
  })
  const [updateProfile] = useMutation(MUTATION_UPDATE_ARTIST)
  const [coverImage, setCoverImage] = useState({ preview: "", raw: "" })
  const [profileImage, setProfileImage] = useState({ preview: "", raw: "" })
  const [progressOfProfileImage, setProgressOfProfileImage] = useState({
    count: 0,
    title: "",
  })
  const [progressOfCoverImage, setProgressOfCoverImage] = useState({
    count: 0,
    title: "",
  })
  const handleProfileImageChange = e => {
    if (e.target.files.length) {
      setProfileImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const handleCoverImageChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const [loaderElement, setLoader] = useButtonLoader(
    "Update Profile",
    "Processing..."
  )

  const basePrefix = `${process.env.STRAPI_SERVER_URL}` // SERVER BASE

  const onSubmit = values => {
    const artistID = userState.artist ? userState.artist.id : null

    setLoader(true)
    // First upload audio file and then create a mediacontent entry with uploaded url and logged in user
    updateProfile({
      variables: {
        input: {
          data: {
            title: values.title,
            contact: values.contact,
            location: values.location,
            about: values.about,
            country:
              values.country && values.country.id
                ? values.country.id
                : undefined,
          },
          where: {
            id: artistID,
          },
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
      .then(response => {
        if (coverImage.raw) {
          const coverFormData = new FormData()
          coverFormData.append("files", coverImage.raw)
          coverFormData.append("ref", "artist")
          coverFormData.append("refId", artistID)
          coverFormData.append("field", "cover")
          coverFormData.append("path", awsArtistImagesPath(values.title))
          axios
            .post(`${basePrefix}/upload`, coverFormData, {
              headers: {
                Authorization: "Bearer " + auth.getToken(),
              },
              onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                setProgressOfCoverImage({
                  count: percentCompleted - 0.05 * percentCompleted,
                  title: "Uploading " + coverImage.name,
                })
              },
            })
            .then(function() {
              setProgressOfCoverImage({
                count: 100,
                title: "Cover Image Successfully Uploaded",
              })
              if (profileImage.raw) {
                const profileFormData = new FormData()
                profileFormData.append("files", profileImage.raw)
                profileFormData.append("ref", "artist")
                profileFormData.append("refId", artistID)
                profileFormData.append("field", "image")
                profileFormData.append(
                  "path",
                  awsArtistImagesPath(values.title)
                )

                axios
                  .post(`${basePrefix}/upload`, profileFormData, {
                    headers: {
                      Authorization: "Bearer " + auth.getToken(),
                    },
                    onUploadProgress: function(progressEvent) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                      setProgressOfProfileImage({
                        count: percentCompleted - 0.05 * percentCompleted,
                        title: "Uploading " + profileImage.name,
                      })
                    },
                  })
                  .then(function() {
                    NotificationManager.success("Profile updated...")
                    setProgressOfProfileImage({
                      count: 100,
                      title: "Profile Image Successfully Uploaded",
                    })
                    setLoader(false)
                  })
              } else {
                NotificationManager.success("Profile updated...")
                setLoader(false)
              }
            })
        } else {
          if (profileImage.raw) {
            const profileFormData = new FormData()
            profileFormData.append("files", profileImage.raw)
            profileFormData.append("ref", "artist")
            profileFormData.append("refId", artistID)
            profileFormData.append("field", "image")
            profileFormData.append("path", awsArtistImagesPath(values.title))

            axios
              .post(`${basePrefix}/upload`, profileFormData, {
                headers: {
                  Authorization: "Bearer " + auth.getToken(),
                },
              })
              .then(function() {
                NotificationManager.success("Profile updated...")
                setLoader(false)
              })
          } else {
            NotificationManager.success("Profile updated...")
            setLoader(false)
          }
        }
      })
      .catch(() => {
        NotificationManager.error("Profile Update failed...", false)
        setLoader(false)
      })
  }

  let coverImageURL =
    artistData && artistData.cover && artistData.cover.url
      ? !artistData.cover.url.startsWith("http")
        ? basePrefix + artistData.cover.url
        : artistData.cover.url
      : undefined

  let profileImageURL =
    artistData && artistData.image && artistData.image.url
      ? !artistData.image.url.startsWith("http")
        ? basePrefix + artistData.image.url
        : artistData.image.url
      : undefined

  return (
    <St.Form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
      <div>
        <St.Label>Name</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="nameTitleError"
          ref={register({
            required: "Please add your name",
          })}
        />
        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Contact Number</St.Label>
        <St.FormInput
          name="contact"
          aria-invalid={errors.contact ? "true" : "false"}
          aria-describedby="contactError"
          ref={register({
            required: "Please add your contact",
          })}
        />
        {errors.contact && (
          <St.ErrorText>{errors.contact.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Profile Image</St.Label>
        <St.FileTypeLabel htmlFor="upload-profile">
          {profileImage.preview ? (
            <St.Image src={profileImage.preview} alt="dummy"></St.Image>
          ) : profileImageURL ? (
            <St.Image src={profileImageURL} alt="dummy"></St.Image>
          ) : (
            <>Click here to Upload Profile Image</>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-profile"
          name="profileImage"
          aria-invalid={errors.profileImage ? "true" : "false"}
          aria-describedby="profileImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleProfileImageChange}
          ref={
            !profileImageURL
              ? register({
                  required: "Please select your profile image",
                })
              : undefined
          }
        />

        {errors.profileImage && (
          <St.ErrorText>{errors.profileImage.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Cover Image</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <>Click here to Upload Cover Image</>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleCoverImageChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please select your cover image",
                })
              : undefined
          }
        />

        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>

      <div>
        <St.Label>Location</St.Label>
        <St.FormInput
          name="location"
          aria-invalid={errors.location ? "true" : "false"}
          aria-describedby="locationError"
          ref={register({
            required: "Please add place where you live",
          })}
        />

        {errors.location && (
          <St.ErrorText>{errors.location.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Country</St.Label>
        <St.FormSelect
          name="country.id"
          aria-invalid={errors.country ? "true" : "false"}
          aria-describedby="countryError"
          defaultValue={artistData.country ? artistData.country.id : null}
          ref={register({
            required: "Please select country",
          })}
        >
          <option value="">-- Select Country --</option>
          {props.countries &&
            props.countries.map((country, i) => (
              country.name != COUNTRY_ALL && <option key={i} value={country.id}>
                {country.name}
              </option>
            ))}
        </St.FormSelect>
        {errors.country && errors.country.id && (
          <St.ErrorText>{errors.country.id.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutError"
          ref={register({
            required: "Please add something about yourself",
          })}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>
      <St.SubmitButton ref={loaderElement}>Update Profile</St.SubmitButton>
      {progressOfProfileImage.count < 100 && (
        <ProgressContent
          progress={progressOfProfileImage.count}
          title={progressOfProfileImage.title}
        />
      )}
      {progressOfCoverImage.count < 100 && (
        <ProgressContent
          progress={progressOfCoverImage.count}
          title={progressOfCoverImage.title}
        />
      )}
    </St.Form>
  )
}

const ContentUserArtist = props => {
  let { device } = useContext(AppContext)
  return (
    <St.ContentWrapper>
      <St.ContentSection>
        <St.LeftAdSection />

        <St.CenterContentWrapper>
          <St.DataTopHeadingWrapper style={{ justifyContent: "center" }}>
            <St.DataTopHeading>Profile</St.DataTopHeading>
          </St.DataTopHeadingWrapper>

          <St.CenterContent>
            <ProfileUpdateForm
              device={device}
              data={props.data}
              countries={props.countries}
            />
          </St.CenterContent>
          <St.BottomAdSection />
        </St.CenterContentWrapper>
        <St.RightAdSection />
      </St.ContentSection>
    </St.ContentWrapper>
  )
}

export default ContentUserArtist
